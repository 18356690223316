*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
overflow: hidden;
}

body {
  font-family: "Poppins", sans-serif;
  background-size: cover;
  background-attachment: fixed;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickMe {
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 700;
}
